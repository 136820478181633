import Business from "../../Pages/Business";
import AppRoutes from "../AppRoutes";
import AppRoutesBusiness from "../AppRoutesBusiness";

function PageContent() {
  return (
    <div className="PageContent">
      <AppRoutesBusiness />
    </div>
  );
}
export default PageContent;
