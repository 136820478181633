import Almanac from "../../Pages/Almanac";
import AppRoutes from "../AppRoutes";
import AppRoutesAlmanac from "../AppRoutesAlmanac";

function PageContent() {
  return (
    <div className="PageContent">
      <AppRoutesAlmanac />
    </div>
  );
}
export default PageContent;
